// --------------------
// VARIABLES
//---------------------

// Colors
$body-color		: #25272A;
$primary        : #F1C44E;
$secondary      : #4196C6;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
$light:         #f4f4f4;
// $dark:          $gray-800;

$slider-title-color : #ffffff;
$slick-dot-color : #ffffff;
$slick-active-dot-color : #42c7b3;

// --------------------
// Container Options
//---------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1165px,
  );
  
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
  xl: 1180px,
  xxl: 1700px,
);
