
// --------------------
// GLOBAL COMPONENTS
//---------------------

// Generic Section Padding - adds padding to each section - most of your sections should use this - override for special cases
section {
    padding: 50px 0px;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding: 80px 0px;
    }
    @include media-breakpoint-up(xl) {
        padding: 120px 0px;
    }
}

// Background Images - positions the background image behind the content in the section
.background-image-container {
    overflow: hidden;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

	img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
    }
    
    // Background Overlay - Adds a colour overlay with transparency options
    &.semi-transparent-bg {
        &-dark {
            background: #000;
            img {
                opacity: 0.6;
            }
        }
        &-light {
            background: #fff;
            img {
                opacity: 0.4;
            }
        }
    }
}

// Youtube Video Embeds
.video-preview-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    
    iframe,
    object,
    embed,
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

	img{
		width: 100%;
	}

    .overlay{
        z-index: 4;

        &:before{
            content:'';
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height: 100%;
            background: rgba(0,0,0,.5);
        }

        img{
            object-fit: cover;
            height: 100%;
        }
    }

	.play-button {
        width: 68px;
		position: absolute;
		left: calc(50% - 34px);
		top: calc(50% - 34px);
		padding: 0;
		margin: 0;
		border: none;
		appearance: none;
		background: none;
		z-index: 5;
		transition: .8s ease;

		&:hover {
			transform: scale(1.1);
			cursor: pointer;
		}
		&:focus {
			outline: 0;
		}

		img {
			width: 100%;
		}

        @include media-breakpoint-up(lg) {
            width: 88px;
            left: calc(50% - 44px);
            top: calc(50% - 44px);
        }
	}

	iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 3;
	}
}

.max-width-image {
    width: 100%;
    max-width: 400px;
    @include media-breakpoint-up(lg) {
        max-width: 100%;
    }
}

.container-fluid {
    max-width: 1500px;

    @include media-breakpoint-up(xxl) {
        max-width: 1820px;
    }
}

.color {
    &-primary {
        color: $primary;
    }
    &-secondary {
        color: $secondary;
    }
}