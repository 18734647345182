footer {
    background: rgba(0,0,0,.6);
    position: absolute;
    left:0;
    bottom:0;
    width:100%;
    z-index: 2;
    padding: 10px 0;

    p{
    	font-size: 1.8rem;
    }

    p a {
    	font-weight: 800;
    }
}