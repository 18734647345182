.industries{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	&__tile{
		margin: 0 0 30px;
		width: 50%;

		@include media-breakpoint-down(md){
			&:last-child{
				margin:0;
			}
		}

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;

		img{
			width: 60px;
		}

		p{
			width: calc(100% - 60px);
			margin:0;
			padding-left: 10px;
		}
	}

	@include media-breakpoint-up(sm){
		&__tile{
			justify-content: center;

			img{
				width: 100px;
			}

			p{
				width: 150px;
				padding-left: 18px;
			}
		}
	}

	@include media-breakpoint-up(lg){
		margin: 40px 0 0;

		&__tile{
			margin: 0 0 40px;
			width: 33.333%;

			img{
				width: 120px;
			}

			p{
				width: calc(100% - 120px);
			}
		}
	}

	@include media-breakpoint-up(xxl){
		&__tile{
			margin: 0 0 70px;

			img{
				width: 144px;
			}

			p{
				width: calc(100% - 144px);
			}
		}
	}
}