
ul, ol {
	&.styled-list {
		margin: 0;
		padding: 0;
		list-style: none;
		
		h4{
			text-transform: uppercase;
			font-weight: 700;
			line-height: (40/24);
			margin: 0 0 10px;

			strong{
				color:$secondary;
			}
		}

		li {
			position: relative;
			padding-left: 70px;
			display: block;
			font-weight: 500;
			line-height: (40/24);

			&::before {
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 50px;
				height: 50px;
				font-size: 2.4rem;
				line-height: 1;
				text-align: center;
				font-weight: bold;
				border-radius: 50%;
				color: white;
				background: $secondary;
			}
			@include media-breakpoint-up(xxl) {
				padding-left: 95px;
				padding-right: 15px;
           		margin-bottom: 6rem;

				&::before {
					width: 60px;
					height: 60px;
					top:-10px;
				}
			
			}
        }
	}
}

ul {
    &.styled-list {
        li {
			&::before {
				content:'';
				background: $secondary url('../images/tick.svg') no-repeat center/20px auto;
				@include media-breakpoint-up(xxl) {
					background-size: 25px auto;
				}
			}
        }
	}

}

ol {
	&.styled-list {
		counter-reset: ol-counter;
		li {
			counter-increment: ol-counter;
			&::before {
				content: counter(ol-counter);
			}
		}
	}
}
