form {
    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select {
        width: 100%;
        padding: 0px 15px 0px 50px;
        border: none;
        border-radius: 0px;
        height: 70px;
        font-size: 1.8rem;
        line-height: 1;
        background: #F4F4F4;
        margin: 0 0 20px;

        @include media-breakpoint-up(sm) {
            padding: 0px 15px 0px 70px;
        }

        @include media-breakpoint-up(xxl){
            font-size: 2.1rem;
        }

        &:-internal-autofill-selected {
            background-color: $white !important;
            background-image: none !important;
            color: -internal-light-dark-color(black, white) !important;
        }

        &::placeholder{
            opacity: 1 !important;
            color: $body-color !important;
        }
    }

    textarea {
        height: 150px !important;
        padding: 20px 15px 20px 50px;

        @include media-breakpoint-up(sm) {
            padding: 22px 15px 20px 70px;
        }
    }

    select {
        appearance: none;
    }

    label {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        line-height: 1;
        &.error {
            display: block;
            margin-bottom: 20px;
            margin-top: -10px;
            color: red;
            font-size: 1.9rem;
        }
        .icon {
            position: absolute;
            top: 20px;
            left: 15px;
            background-size: 80%;
            width: 30px;
            height: 30px;
            z-index: 999;
            margin: auto;
            background-repeat: no-repeat;
            background-position: center center;

            &.icon-name {
                background-image: url('../../../assets/prod/images/icon-username.svg');
            }
            &.icon-email {
                background-image: url('../../../assets/prod/images/icon-email.svg');
            }
            &.icon-phone {
                background-image: url('../../../assets/prod/images/icon-phone.svg');
            }
            &.icon-pain {
                background-image: url('../../../assets/prod/images/icon-postcode.png');
            }
            &.icon-message {
                background-image: url('../../../assets/prod/images/icon-comment.svg');
            }
        }
    }
}

.form-group {
    width: 100%;
    display: block;
    margin:0;
}