// --------------------
// BUTTON STYLE
//---------------------

.btn,
button,
input[type=submit],
input[type=button] {
    width: 100%;
    border: 0;
    border-radius: 4px;
    margin-bottom: 12px;
    font-size: 1.8rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 30px 10px;
    white-space: normal;
    background: $primary;
    color: $body-color;
    transition: background-color .4s ease-in-out;
    font-weight: 800;
    letter-spacing: -.0285em;

    @include media-breakpoint-up(md) {
        padding: 32px 0;
    }

    @include media-breakpoint-up(xxl){
        font-size: 2rem;
    }

    &:hover,
    &:focus,
    &:active {
        color: #fff;
        background: rgba($primary, .8);
    }
}

.btn-section {
    margin: 4rem 0 0;

    @include media-breakpoint-up(lg) {
        max-width: 550px;
    }

    p {
        width: 90%;
        margin: 0 auto;
        font-style: italic;
        font-weight: 800;
        letter-spacing: .02380em;
        line-height: (29/21);
        color:$dark;
        text-align: center;

        @include media-breakpoint-up(xxl){
            font-size: 2.1rem;
        }
    }

    form &{
        p{
            width:90%;
            font-size: 1.6rem;
            line-height: (23/18);
            letter-spacing: -0.011em;
            max-width: 350px;

            @include media-breakpoint-up(xxl){
                font-size: 1.8rem;
                width:100%;
                max-width: 100%;
            }
        }
    }
}