header {

    padding: 25px 15px;
    background-color: transparent;
    transition: background-color 0.4s ease, padding .4s ease;
    
    &.scrolled {
        padding: 10px 15px;
        box-shadow: -3px -3px 6px 8px rgba(0,0,0,.05);
        background-color: #FFF;
    }

    @include media-breakpoint-up(md){
        padding: 35px 15px;

        &.scrolled {
            padding: 13px 15px;
        }
    }

    @include media-breakpoint-up(xxl){
        padding: 45px 15px;

        &.scrolled {
            padding: 15px 15px;
        }
    }
}

.header-logo {
    // filter no bingo in IE
    background: url('../images/site-logo-cover.png') no-repeat right/cover;
    display: block;
    height: 60px;
    width: 158px;
    transition: transform 400ms ease;

    .scrolled & {
        background-position: left;
        transform: scale(.85);
        transform-origin: left;
    }

    @include media-breakpoint-up(md){
        height: 75px;
        width: 198px;
    }

    @include media-breakpoint-up(xxl){
        height: 85px;
        width: 224px;
    }
}

.header-phone {
    line-height: 1.2; 

    .phone-icon {
        margin-right: 14px;
        img,
        svg {
            max-width: 50px;
            width: 100%;
        }

        @include media-breakpoint-up(xxl) {
            img,
            svg {
                max-width: 65px;
                width: 100%;
            }
        }
    }

    span,
    em{
        color:#FFF;
        transition: color .4s ease;

        .scrolled & {
            color:$body-color;
        }
    }

    // Got questions (if it exists)
    span {
        font-size: 1.5rem;   
        margin: 0 0 4px;
    }

    // Phone number text
    em {
        font-style: normal;
        font-weight: bold;
        font-size: 2.4rem;   
    }

    @include media-breakpoint-up(xxl) {
        span{
            font-size: 1.7rem;
        }
        em{
            font-size: 3.4rem;
        }
    }
} 


