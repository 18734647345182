// --------------------
// Include Fonts Here
// --------------------

@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-regular.woff2') format('woff2'),
    url('../../fonts/lato-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: "swap";
}
@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-medium.woff2') format('woff2'),
    url('../../fonts/lato-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: "swap";
}
@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-mediumitalic.woff2') format('woff2'),
    url('../../fonts/lato-mediumitalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: "swap";
}
@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-bold.woff2') format('woff2'),
    url('../../fonts/lato-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: "swap";
}
@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-bolditalic.woff2') format('woff2'),
    url('../../fonts/lato-bolditalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: "swap";
}
@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-heavy.woff2') format('woff2'),
    url('../../fonts/lato-heavy.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: "swap";
}
@font-face {
    font-family: 'Lato';
    src: url('../../fonts/lato-black.woff2') format('woff2'),
    url('../../fonts/lato-black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: "swap";
}


$headings-font: 'Lato', sans-serif;
$body-font: 'Lato', sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 50%;  //1rem = 8px
    @include media-breakpoint-up(md) {
        font-size: 56.25%; //1rem = 9px
    }
    @include media-breakpoint-up(xl) {
        font-size: 62.5%; // 1rem = 10px
    }
}

html, body {
    @include font-smoothing('on');
    font-size: 1.8rem; //Laptop: 20px, Ipads: 18px, Mobiles: 16px
    line-height: 1.4;
    font-family: $body-font;
    position: relative;

    @include media-breakpoint-up(lg) {
        font-size: 2rem;  //Laptop: 50px, Ipads: 45px, Mobiles: 40px
    }

    @include media-breakpoint-up(xxl) {
        font-size: 2.4rem;  //Laptop: 50px, Ipads: 45px, Mobiles: 40px
    }
}

p {
    margin-bottom: 2.5rem;
    letter-spacing: -0.0041em;
}

// Headline padding
h1, .h1, 
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    line-height: 1.3;
    margin-bottom: 2rem;
    font-family: $headings-font;
    font-weight: 800;
    text-transform: capitalize;
    color:$body-color;
}

h1, .h1 {
    font-size: 4rem;  //Laptop: 50px, Ipads: 45px, Mobiles: 40px
    line-height: (68/56);
    letter-spacing: -0.016em;
    font-weight: 900;

    @include media-breakpoint-up(lg) {
        font-size: 4.5rem;  //Laptop: 50px, Ipads: 45px, Mobiles: 40px
    }

    @include media-breakpoint-up(xxl) {
        font-size: 5.6rem;  //Laptop: 50px, Ipads: 45px, Mobiles: 40px
    }
}

h2, .h2 {
    font-size: 3rem;  //Laptop: 46px, Ipads: 41.4px, Mobiles: 36.8px
    margin-bottom: 3rem;

    @include media-breakpoint-up(lg) {
        font-size: 3.5rem;  //Laptop: 50px, Ipads: 45px, Mobiles: 40px
    }

    @include media-breakpoint-up(xxl) {
        font-size: 4.6rem;  //Laptop: 50px, Ipads: 45px, Mobiles: 40px
    }
}

h3, .h3 {
    font-size: 3rem;  //Laptop: 30px, Ipads: 27px, Mobiles: 24px
}

h4, .h4 {
    font-size: 2rem;  //Laptop: 24px, Ipads: 21.6px, Mobiles: 19.2px

    @include media-breakpoint-up(xxl){
        font-size: 2.4rem;  //Laptop: 20px, Ipads: 18px, Mobiles: 16px
    }
}

h5, .h5 {
    font-size: 2rem;  //Laptop: 20px, Ipads: 18px, Mobiles: 16px

    @include media-breakpoint-up(xxl){
        font-size: 2.1rem;  //Laptop: 20px, Ipads: 18px, Mobiles: 16px
    }
}

h6, .h6 {
    font-size: 1.8rem;  //Laptop: 18px, Ipads: 16.2px, Mobiles: 14.4px
}

// Hero Specific Font Sizes
.eyebrow {
    text-transform: uppercase;
    color: #FFF;
    font-weight: 800;
    line-height: (33/24);
}

.subheadline {
    line-height: (34/24);
    font-weight: 700;
    letter-spacing: -0.008333em;
    opacity: .8;
    margin: 0 0 3rem;
   
    @include media-breakpoint-up(xxl) {
        margin: 0 0 4.5rem;
    }
}





