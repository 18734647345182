/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    *{
        outline: none;
    }
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    float: left;
    min-height: 1px;

    p {
        margin: 0 auto 1.8rem;
    }
    h4 {
        color: $slider-title-color;
        margin-bottom: 0;
        text-transform: uppercase;
    }
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}


// Custom Slick Styles

// Arrows
.slick-arrow {
    position: absolute;
    top: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.prev,
    &.prev-arrow {
        left:0;
    }
    &.next,
    &.next-arrow {
        right:0;
    }

    @include media-breakpoint-down(md){
        max-width: 30px;

        &.prev,
        &.prev-arrow {
            left:-25px;
        }
        &.next,
        &.next-arrow {
            right:-25px;
        }
    }   

    @include media-breakpoint-down(sm){
        display: none !important;
    }
}


// Dots
.slick-dots {
    display: flex;
    justify-content: center;
    margin: 4rem 0 0;
    padding: 0;
    list-style-type: none;

    @include media-breakpoint-up(xl){
        margin: 5.5rem 0 0;
    }

    li {
        margin:0 4.5px;
        width: 35px;
        height: 5px;
        background: #FFF;
        cursor: pointer;

        &.slick-active{
            background:$secondary;
        }
    }

    button{
        display: none;        
    }
}