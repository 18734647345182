.step {
    background: white;
    box-shadow: 0 6px 15px 4px rgba(0,0,0,.15);
    max-width: 805px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
    margin: 0 auto 25px;
    border-radius:4px;
    position: relative;
    padding: 30px 30px 30px 90px;

    &-number {
        background: $secondary;
        color: #fff;
        
        width: 50px;
        height: 50px;
        line-height: 50px;

        font-size: 2.4rem;
        font-weight: 700;
        border-radius: 50%;
        text-align: center;
        position: absolute;
        left:20px;
        top:30px;
    }

    &-title{
        text-transform: uppercase;
        line-height: (40/24);
        font-weight: 800;
        margin:0 0 1rem;
    }

    &-copy{
        line-height: (40/24);
        letter-spacing: 0;
        margin:0;
    }

    @include media-breakpoint-up(lg){
        margin: 0 auto 35px;
    }

    @include media-breakpoint-up(xxl){
        padding: 40px 40px 40px 130px;

        &-number{
             left:34px;
            top:34px;

            width: 60px;
            height: 60px;
            line-height: 60px;
        }
    }
}
