#section-1{

	padding: 110px 0 50px;

	h1,.subheadline{
		color:#FFF;
	}
	.reviews{
		max-width: 250px;
		width:100%;
	}

	.form-container{
		background: #FFF;
		border-radius: 4px;
		padding: 15px;
		box-shadow: 0 6px 15px 4px rgba(#000, .15);
		margin: 35px auto 0;

		.btn-section{
			max-width: 100%;
		}
	}

	@include media-breakpoint-down(md){
		.eyebrow{
			margin: 0 0 1.5rem;
		}
	}

	@include media-breakpoint-up(sm){
		.form-container{
			padding: 30px;
		}
	}

	@include media-breakpoint-up(md){
		padding: 150px 0 80px;
	}

	@include media-breakpoint-up(lg){
		padding: 150px 0 130px;

		.form-container{
			margin:0;
		}

		.reviews{
			max-width: 320px;
		}
	}
    
	@include media-breakpoint-up(xxl){
		padding: 190px 0 165px;

		h1{
			padding-right: 30px;
		}

		.reviews{
    		max-width: 382px;
    	}
	}
}

#section-2{
	padding: 30px 0;

	h5{
		opacity: .5;
		margin:0;
		font-weight: 800;
		text-transform: uppercase;
	}


	.slider-container{
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		.slider{
			width:100%;
		}

	    @include media-breakpoint-up(xl) {
			h5{
				width: 14.29%;
			}
			.slider{
				width: 85.71%;
			}
		}
	}

	@include media-breakpoint-up(md){
		padding: 40px 0;
	}

	@include media-breakpoint-up(xl){
		padding: 60px 0;
	}

	@include media-breakpoint-up(xxl){
	    padding: 82px 0;
	}

	@include media-breakpoint-down(xl){
		img{
			width:100%;
			max-width: 130px;
			max-height: 65px;
			object-fit: contain;
			margin: 0 auto;
		}
	}

	@include media-breakpoint-down(lg){
		h5{
			margin: 0 auto 20px;
		}
	}

	@include media-breakpoint-down(md){
		img{
			max-height: 55px;
		}
	}
}

#section-3{
	padding-bottom:0;

	@include media-breakpoint-up(xxl) {
        padding: 140px 0px 0;
    }
}

#section-4{

	.image-container{
		margin: 0 0 30px;

		p{
			@extend .h5;
			opacity: .5;
			font-style:italic;
			font-weight: 800;
			letter-spacing: .02380em;
			margin: 15px 0 0;
		}
	}

	@include media-breakpoint-down(lg) {
		padding-top: 20px;

		.image-container{
			margin-bottom: 0px;

			p{
				margin:0;
			}
		}
	}

	@include media-breakpoint-up(lg) {
        padding-bottom: 150px;
    }

	@include media-breakpoint-up(xxl){
		.image-container{
			position: relative;
			margin: 0;
			left: -200px;
			width: 685px;

			img{
				width:100%;
			}

			p{
				margin: 25px 0 0;
			}
		}
	}
}

#section-5{
	padding:40px 0;

	p{
		font-weight: 800;
		text-transform: uppercase;
		margin: 15px auto 0;
		font-size: 1.6rem;
	}

	img{
		margin: 0 auto;
	}

	@include media-breakpoint-down(xl) {
		img{
			max-width: 50px;
			max-height: 50px;
			object-fit: contain;
		}
	}

	@include media-breakpoint-down(md) {
		img{
			max-width: 40px;
			max-height: 40px;
			object-fit: contain;
		}
	}


	@include media-breakpoint-up(xxl) {
		padding: 50px 0;

		p{
			font-size: 2.1rem;
		}
	}
}

#section-6{

	img{
		width: 130px;
		margin: 0 0 15px;
	}

	ol{
		margin-top: 20px;

		li:last-child{
			margin-bottom: 40px;
		}
	}

	@include media-breakpoint-up(xxl) {
        padding: 115px 0px 160px;

		img{
			width: 159px;
		}

		ol{
			margin-top: 40px;
		}
    }


	.btn-section{
		max-width: 100%;
		margin-top: 0;

		p{
			max-width: 75%;
		}
	}
}

#section-7{
	@include media-breakpoint-up(xxl) {
        padding: 150px 0 130px;
    }

	img{
		@include media-breakpoint-up(xxl) {
			max-width: none;
			width: 753px;
			margin-left:45px;
		}	
	}
}

#section-8{
	@include media-breakpoint-up(xxl) {
        padding: 150px 0 150px;
    }

	img{
		@include media-breakpoint-up(xxl) {
			max-width: none;
			width: 772px;
			position: relative;
			left: -260px;
		}	
	}
}

#section-9{

	.reviews{
		max-width: 250px;
		width:100%;
		margin: 15px auto 25px;
	}

	.reviews-large{
		max-width: 1630px;
		margin: 0 auto;
		display: block;
		width:100%;
	}

	.reviews-slider{
		.slick-track{
			align-items:stretch;
		}
		.item{
			background:#FFF;
			border-radius:4px;
			padding: 5px;
			margin: 0 5px;

			img{
				display: block;
				width: 100%;
				max-height: 120px;
				object-fit: contain;
			}
		}
	}

	@include media-breakpoint-up(sm){

		.reviews-slider{

			.slick-track{
				align-items:center;
			}

			.item{
				margin: 0 5px;
				padding: 10px;

				img{
					max-height: 150px;
				}
			}
		}
	}

	@include media-breakpoint-up(lg){
		.reviews{
			max-width: 320px;
		}
		.reviews-large{
			max-width: 1630px;
		}
	}
    
	@include media-breakpoint-up(xxl){
        padding: 140px 0 130px;

		.reviews{
			max-width: 382px;
			margin: 25px auto 25px;
		}

		.reviews-large{
			max-width: 1630px;
		}
	}
}

#section-10{

	ul{
		margin-top: 15px;
	}

	@include media-breakpoint-up(xxl) {
        padding: 150px 0 160px;

        ul{
			margin-top: 30px;
		}
    }

	ul{
		li:last-child{
			margin-bottom: 38px;
		}
	}

	.btn-section{
		max-width: 100%;
		margin-top: 0;

		@include media-breakpoint-up(lg) {
			p{
				max-width: 75%;
			}
		}
	}
}

#section-11{
	@include media-breakpoint-up(xxl) {
        padding: 130px 0 75px;
    }
}

#section-12{
	h2{
    	margin: 0 0 4.5rem;
    }

	@include media-breakpoint-up(xxl) {
        padding: 130px 0 130px;

	    h2{
	    	margin: 0 0 5.5rem;
	    }
    }

	img{
		max-width: 325px;
		margin: 0 auto;
		display: block;
	}

	p.terms{
		font-style: italic;
		opacity: .5;
		font-weight: 700;
		letter-spacing: .0238em;

		@include media-breakpoint-up(xxl) {
			font-size: 2.1rem;
		}
	}
}

#section-13,
#section-13-2{
	@include media-breakpoint-up(xxl) {
        padding: 140px 0 120px;

		.container{
			max-width: 1355px;
		}
    }

	h2{
		color:#FFF;
    	margin: 0 0 4.5rem;

		@include media-breakpoint-up(xl) {
	    	margin: 0 0 5.5rem;
		}
	}

	.slider{
		max-width: 800px;
		margin: 0 auto;
	}

	.slide{
		background: #000;
		border: 10px solid #FFF;

		@include media-breakpoint-up(md) {
			border: 21px solid #FFF;
		}
	}
}

#section-13-2{
	.slider{
		max-width: 700px;
	}

	.slick-track{
		align-items: flex-start;
	}

	.slick-arrow{
		top: 15%;
	}

	.slide{
		background: none;
		border:none;

		p{
			color:#FFF;

			&.quote{
				font-style: italic;
				margin: 0 0 2.5rem;
			    line-height: 1.667;
			}

			&.author{
				font-weight: 700;
				margin: 0 0 1rem;
			}

			&.company{
				font-weight: 800;
				font-size: 1.6rem;
				margin:0;
			}
		}
	}

	@include media-breakpoint-up(md) {
		.slide{
			p{
				&.quote{
					margin: 0 0 3.5rem;
				}
				&.author{
					margin: 0 0 1.5rem;
				}
			}
		}
	}

	@include media-breakpoint-up(xxl) {
		.slider{
			max-width: 1020px;
		}

		.slide{
			p{
				&.company{
					font-size: 1.8rem;
				}
			}
		}
	}
}

#last-section{
	padding-bottom: 200px;

	@include media-breakpoint-up(lg) {
		padding-bottom: 400px;
	}

	@include media-breakpoint-up(xxl) {
        padding: 140px 0 630px;
    }

    h2{
    	margin: 0 0 4.5rem;
    }

    ul{
    	li{
    		margin-bottom: 3rem;

    		&:before{
    			top: 0px;
    		}
    	}
    }

    p{
    	line-height: (40/24);
    }

    textarea{
    	min-height: 190px;
    }

    form{
    	margin: 4rem 0 0;
    }

    .btn-section{
    	max-width:100%;

    	p{
			@include media-breakpoint-up(lg) {
	    		max-width: 62%;
	    	}
    	}
    }

	.background-image-container{
		max-height: 600px;
		bottom:0;
		top:auto;

		img{
			object-position: top;
		}

		@include media-breakpoint-up(lg) {
			max-height: 1320px;
		}
	}


	.terms{
		margin: 30px 0 0;

		p{
			font-weight: 700;
		}

		ul{
			padding:0;
			margin:0;
			list-style: none;

			li{
				line-height: (23/18);
				font-weight: 500;
				font-style: italic;
				margin:0 0 10px;
				position: relative;
				padding-left: 25px;

				&:before{
					content:'';
					width: 10px;
					height: 10px;
					border-radius:50%;
					background:#25272A;
					opacity: .5;
					position: absolute;
					left:0;
					top:8px;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			margin: 50px 0 0;

			p{
				font-size: 1.8rem;
			}

			ul{
				li{
					margin: 0 0 20px;
					font-size: 1.8rem;
				}
			}
		}
	}
}


section {
    &.gallery {
        .slide img {
            width: 100%;
        }
    }
}